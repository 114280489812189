import React, {useState} from 'react'
import Button from '../../../../../../components/Button'
import Dialog from '../../../../../../components/Dialog'
import Text from '../../../../../../components/Text'
import TheoristLookup from '../../../../../../components/TheoristLookup'
import {classList} from '../../../../../../services/util'
import CollabCard from '../CollabCard'
import './styles.scss'

const MAX_COLLABS = 3

export default function ProductCollaborators({canEdit, className, collaborators = [], onChange}) {
  const [dialogOpen, setDialogOpen] = useState(false)
  return <div className={classList(['ProductCollaborators', className])}>
    {collaborators.length > 0 && <Text styleVariant="heading3">Collaborators</Text>}
    {collaborators.map(handle => <CollabCard
      className="ProductCollaborators__collaborator"
      key={`ProductCollaborators ${handle}`}
      onRemove={canEdit ? () => onChange(collaborators.filter(h => h !== handle)) : null}
      theoristHandle={handle} />)}
    {canEdit && <>
      {collaborators.length < MAX_COLLABS ? <>
        <Button
          className="ProductCollaborators__inviteCollaborators"
          onClick={() => setDialogOpen(true)}
          slim
          styleVariant="tertiary">
          Invite collaborators
        </Button>
        <Dialog
          title="Invite collaborators"
          open={dialogOpen}
          onDismiss={() => setDialogOpen(false)}>
          <TheoristLookup
            className="ProductCollaborators__collaboratorSearch"
            onSelect={(theorist) => {
              if (collaborators.length < MAX_COLLABS) {
                onChange([...collaborators, theorist.theoristHandle])
              }
            }} />
        </Dialog>
      </> : <Text styleVariant="body2">
        You can add up to {MAX_COLLABS} collaborators. Remove someone to add more.
      </Text>}
    </>}
  </div>
}
