import React from 'react'
import moment from 'moment'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import DashMetricCard from '../../components/DashMetricCard'
import './styles.scss'

export default function DashMetrics({className, metrics}) {
  const currentYear = moment().year()
  const stats = !metrics ? {} : metrics[currentYear] ?? {}
  const averageRating = (stats.reviewTotal ?? 0) / (stats.reviewCount ?? 1)
  const orderCount = stats.orderCount ?? 0
  const shippingSpeed = Math.round((stats.shippingDays ?? 0) / (orderCount || 1))
  const cancellationRate = (stats.cancellationCount ?? 0) / (orderCount || 1)
  const overallElite = metrics?.overall?.elite === true
  return <div className={classList(['DashMetrics', className])}>
    <Text styleVariant="heading3">Your {currentYear} stats</Text>
    <Text className="DashMetrics__explainer">
      Get recognized and rewarded for exceptional work.
      Achieve the goals below to {!overallElite ? 'reach' : 'maintain'} Top Theorist status.
    </Text>
    <div className="DashMetrics__row">
      {[{
        value: averageRating.toFixed(1),
        unit: 'star',
        unitType: 'icon',
        label: 'Overall rating',
        criteria: '4.5',
        achieved: averageRating > 4.5,
        explainer: 'To meet this goal, you need to have a 4.5 or higher average overall rating based on reviews of ' +
          'orders that you fulfill or reviews left directly on your theorist profile.',
      }, {
        value: shippingSpeed,
        unit: shippingSpeed === 1 ? 'day' : 'days',
        unitType: 'text',
        label: 'Shipping speed',
        criteria: '3 days',
        achieved: shippingSpeed < 3.5,
        explainer: 'To meet this goal, you need to ship orders within 3 days from the day that you receive them. ' +
          'For orders where you are waiting on materials, the shipping time is calculated from the date you receive ' +
          'all materials necessary to fulfill the order.',
      }, {
        value: orderCount,
        unit: '',
        unitType: 'text',
        label: 'Orders completed',
        criteria: '10',
        achieved: orderCount > 9,
        explainer: 'To meet this goal, you need to complete at least 10 orders per year. Orders are marked as ' +
          'completed within a few days of the customer receiving delivery of all items in the order.',
      }, {
        value: (cancellationRate * 100).toFixed(cancellationRate < 0.1 ? 1 : 0),
        unit: '%',
        unitType: 'text',
        label: 'Cancellation rate',
        criteria: 'Less than 5%',
        achieved: cancellationRate < 0.05,
        explainer: 'To meet this goal, you need to cancel fewer than 5% of your orders. Your cancellation rate ' +
          'includes orders that are cancelled due to a product that you listed no longer being available or orders ' +
          'that are cancelled at the request of a customer due to a delay in shipping. This doesn\'t include ' +
          'cancellations that are made for reasons determined to not be your fault on a case-by-case basis.',
      }].map((metric) => <DashMetricCard
        className="DashMetrics__card"
        key={`DashMetrics ${metric.label}`}
        {...metric} />)}
    </div>
  </div>
}
