import React from 'react'
import IconButton from '../../../../../../components/IconButton'
import ProductVendor from '../../../../../../components/ProductVendor'
import {useTheoristByHandle} from '../../../../../../services/contentful'
import {classList} from '../../../../../../services/util'
import './styles.scss'

export default function CollabCard({className, onRemove, theoristHandle}) {
  const theorist = useTheoristByHandle(theoristHandle)
  return <div className={classList(['CollabCard', className])}>
    <ProductVendor theorist={theorist} />
    {onRemove && <IconButton
      className="CollabCard__removeCollaborator"
      icon="clear"
      onClick={onRemove} />}
  </div>
}
